var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import PQueue from 'p-queue';
import { v4 as uuid } from 'uuid';
import EventEmitter from 'eventemitter3';
import { debounce } from 'debounce';
import { ref, unref, watch } from 'vue';
import { isTemporaryIdentifier, MutationState } from '.';
export class ActionQueue extends EventEmitter {
    constructor(opts) {
        var _a, _b, _c;
        super();
        this.temporaryIdentifiers = [];
        this.actionIdPromiseMapping = {};
        this.loadState = null;
        this.actionQueueItems = ref([]);
        this.persistState = debounce((state) => __awaiter(this, void 0, void 0, function* () {
            yield opts.persistState(state)
                .catch((err) => {
                throw new Error(`Failed to persist \`ActionQueue\` state: \`${err.message}\``);
            });
        }), 100);
        this.loadState = opts.loadState;
        this.loadState()
            .then((items) => {
            this.actionQueueItems.value = items ? items.filter(item => !item.session).concat(this.actionQueueItems.value) : this.actionQueueItems.value;
        })
            .catch((err) => {
            throw new Error(`Failed to load \`ActionQueue\` state: ${err.message}`);
        });
        this.executeAction = opts.executeAction;
        this.applyMutation = opts.applyMutation;
        this.updateMutation = opts.updateMutation;
        this.commitMutation = opts.commitMutation;
        this.cancelMutation = opts.cancelMutation;
        this.maxTries = (_a = opts.maxTries) !== null && _a !== void 0 ? _a : 3;
        this.retryWaitTime = (_b = opts.retryWaitTime) !== null && _b !== void 0 ? _b : 1000;
        this.actionQueue = new PQueue({ concurrency: (_c = opts.concurrency) !== null && _c !== void 0 ? _c : 10, autoStart: false });
        watch(this.actionQueueItems, () => {
            if (this.persistState) {
                // todo: remove JSON parsing
                void this.persistState(JSON.parse(JSON.stringify(unref(this.actionQueueItems))));
            }
        }, { deep: true });
    }
    start() {
        this.actionQueue.start();
    }
    pause() {
        this.actionQueue.pause();
    }
    getQueue() {
        return unref(this.actionQueueItems);
    }
    queueAction(queueItem) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            queueItem.actionId = uuid();
            queueItem.timestamp = Date.now();
            queueItem.maxTries = (_a = queueItem.maxTries) !== null && _a !== void 0 ? _a : this.maxTries;
            queueItem.timesTried = 0;
            this.actionQueueItems.value.push(queueItem);
            this.applyMutation(queueItem.actionId, queueItem.resource, queueItem.action, queueItem.parameters);
            return yield this.actionQueue.add(() => __awaiter(this, void 0, void 0, function* () {
                return yield this.process(queueItem);
            }));
        });
    }
    updateQueuedAction(actionId, temporaryIdentifiers) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!temporaryIdentifiers)
                return;
            const index = this.actionQueueItems.value.findIndex(item => item.actionId === actionId);
            if (index !== -1) {
                (_a = this.actionQueueItems.value[index].temporaryIdentifiers) === null || _a === void 0 ? void 0 : _a.push(...temporaryIdentifiers);
            }
        });
    }
    cancelAction(actionId) {
        return __awaiter(this, void 0, void 0, function* () {
            // this.actionQueue.pause()
            const index = this.actionQueueItems.value.findIndex(item => item.actionId === actionId);
            if (index !== -1) {
                const { resource } = this.actionQueueItems.value[index];
                this.actionQueueItems.value.splice(index, 1);
                this.cancelMutation(actionId, resource);
            }
            const dependingOn = [actionId];
            while (true) {
                const item = this.actionQueueItems.value.find(item => item.dependingOn && !dependingOn.includes(item.dependingOn));
                if (!item) {
                    break;
                }
                const { resource } = this.actionQueueItems.value[index];
                this.cancelMutation(item.actionId, resource);
                dependingOn.push(item.actionId);
                this.actionQueueItems.value.splice(this.actionQueueItems.value.findIndex(i => i.actionId === item.actionId, 1));
            }
            this.actionQueue.clear();
            yield this.pickup();
        });
    }
    processParameters(parameters) {
        const copyParameters = JSON.parse(JSON.stringify(parameters));
        const parseParameter = (parameter) => {
            if (!parameter)
                return parameter;
            if (typeof parameter === 'string' && isTemporaryIdentifier(parameter)) {
                const tmpId = this.temporaryIdentifiers.find(tmp => tmp.temporaryId === parameter);
                if (tmpId === null || tmpId === void 0 ? void 0 : tmpId.id)
                    return tmpId.id;
                return parameter;
            }
            if (typeof parameter === 'object' && !Array.isArray(parameter)) {
                Object.keys(parameter).forEach((key) => {
                    parameter[key] = parseParameter(parameter[key]);
                });
            }
            else if (Array.isArray(parameter)) {
                parameter.forEach((param, index) => {
                    parameter[index] = parseParameter(param);
                });
            }
            return parameter;
        };
        return copyParameters.map(parameter => parseParameter(parameter));
    }
    process(actionQueueItem) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!actionQueueItem.actionId) {
                throw new Error('actionQueueItem.actionId is not set');
            }
            if (actionQueueItem.dependingOn) {
                yield this.actionIdPromiseMapping[actionQueueItem.dependingOn];
            }
            this.actionIdPromiseMapping[actionQueueItem.actionId] = (() => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                let actionResult;
                try {
                    actionResult = yield this.executeAction(Object.assign(Object.assign({}, actionQueueItem), { parameters: this.processParameters(actionQueueItem.parameters) }));
                    if (actionResult.mutation.getState() === MutationState.cancelled) {
                        this.cancelMutation(actionQueueItem.actionId, actionQueueItem.resource);
                    }
                    else {
                        this.commitMutation(actionQueueItem.actionId, actionQueueItem.resource, actionQueueItem.action, actionResult.mutation.getParameters());
                    }
                    const temporaryIdentifiers = actionResult.mutation.getTemporaryIdentifiers();
                    this.temporaryIdentifiers.push(...temporaryIdentifiers);
                }
                catch (err) {
                    // todo: here is an error
                    if (!actionQueueItem.timesTried)
                        actionQueueItem.timesTried = 0;
                    actionQueueItem.timesTried++;
                    actionQueueItem.lastError = err.message;
                    if (actionQueueItem.timesTried >= ((_a = actionQueueItem.maxTries) !== null && _a !== void 0 ? _a : this.maxTries)) {
                        this.cancelMutation(actionQueueItem.actionId, actionQueueItem.resource);
                        this.emit('error', err, actionQueueItem);
                        const index = this.actionQueueItems.value.findIndex(item => item.actionId === actionQueueItem.actionId);
                        if (index !== -1) {
                            this.actionQueueItems.value.splice(index, 1);
                        }
                        throw err;
                    }
                    else {
                        const index = this.actionQueueItems.value.findIndex(item => item.actionId === actionQueueItem.actionId);
                        if (index !== -1) {
                            this.actionQueueItems.value[index] = actionQueueItem;
                        }
                        setTimeout(() => {
                            void this.process(actionQueueItem);
                        }, (_b = actionQueueItem.retryWaitTime) !== null && _b !== void 0 ? _b : this.retryWaitTime);
                        throw err;
                    }
                }
                const index = this.actionQueueItems.value.findIndex(item => item.actionId === actionQueueItem.actionId);
                if (index !== -1) {
                    this.actionQueueItems.value.splice(index, 1);
                }
                this.actionQueueItems.value = this.actionQueueItems.value.map((actionQueueItem) => {
                    actionQueueItem.parameters = this.processParameters(actionQueueItem.parameters);
                    return actionQueueItem;
                });
                return actionResult.result;
            }))();
            return yield this.actionIdPromiseMapping[actionQueueItem.actionId];
        });
    }
    pickup() {
        return __awaiter(this, void 0, void 0, function* () {
            this.actionQueue.clear();
            this.actionQueueItems.value.forEach((queueItem) => {
                this.applyMutation(queueItem.actionId, queueItem.resource, queueItem.action, queueItem.parameters);
                void this.actionQueue.add(() => __awaiter(this, void 0, void 0, function* () {
                    return yield this.process(queueItem);
                }));
            });
        });
    }
}
