import { EventEmitter } from 'eventemitter3';
export class BrowserConnectionIndicator extends EventEmitter {
    constructor() {
        super();
        window.addEventListener('offline', () => {
            this.emit('connection', false);
        });
        window.addEventListener('online', () => {
            this.emit('connection', true);
        });
    }
    isConnected() {
        return window.navigator.onLine;
    }
}
