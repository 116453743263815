import { mutationKey } from '../Resource';
export function Queueable(opts = {}) {
    return (target, key, descriptor) => {
        const mutationPropertyDescriptor = Object.getOwnPropertyDescriptor(target, mutationKey);
        const fn = descriptor.value;
        descriptor.value = function (...args) {
            var _a;
            return target.queueAction.call(this, key, args, { session: (_a = opts.session) !== null && _a !== void 0 ? _a : false });
        };
        if (mutationPropertyDescriptor) {
            const mutationFn = mutationPropertyDescriptor.value.fn;
            Object.defineProperty(descriptor.value, mutationKey, {
                value: {
                    fn: mutationFn
                },
                writable: true,
                enumerable: false,
                configurable: false
            });
        }
        Object.defineProperty(descriptor.value, 'originalFn', {
            value: fn,
            writable: false,
            enumerable: false,
            configurable: false
        });
    };
}
