var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import localforage from 'localforage';
import { EventEmitter } from 'eventemitter3';
import { ActionQueue } from './ActionQueue';
export class Eremite extends EventEmitter {
    constructor(opts) {
        var _a, _b, _c, _d;
        super();
        this.disconnected = false;
        this.name = (_a = opts.name) !== null && _a !== void 0 ? _a : 'default';
        this.plugins = (_b = opts.plugins) !== null && _b !== void 0 ? _b : [];
        this.resources = (_c = opts.resources) !== null && _c !== void 0 ? _c : {};
        this.connectionIndicator = opts.connectionIndicator;
        Object.values(this.resources).forEach((resource) => {
            const getMutationsByModule = (mutations) => {
                const mutationsByModule = {};
                mutations.forEach(({ id, module, ts, fn }) => {
                    if (!mutationsByModule[module])
                        mutationsByModule[module] = [];
                    mutationsByModule[module].push({ id, ts: ts, fn: fn });
                });
                return mutationsByModule;
            };
            resource.on('internal:externalMutations:create', (mutations) => {
                const mutationsByModule = getMutationsByModule(mutations);
                Object.keys(mutationsByModule).forEach((module) => {
                    this.getResource(module).addExternalMutations(mutationsByModule[module]);
                });
            });
            resource.on('internal:externalMutations:update', (mutations) => {
                const mutationsByModule = getMutationsByModule(mutations);
                Object.keys(mutationsByModule).forEach((module) => {
                    this.getResource(module).updateExternalMutations(mutationsByModule[module]);
                });
            });
            resource.on('internal:externalMutations:commit', (mutations) => {
                const mutationsByModule = getMutationsByModule(mutations);
                Object.keys(mutationsByModule).forEach((module) => {
                    this.getResource(module).commitExternalMutations(mutationsByModule[module]);
                });
            });
            resource.on('internal:externalMutations:cancel', (mutations) => {
                mutations.forEach((module) => {
                    Object.values(this.resources.forEach).forEach((resource) => {
                        resource.cancelExternalMutations(mutations);
                    });
                });
            });
        });
        const storeName = (_d = opts.name) !== null && _d !== void 0 ? _d : 'eremite';
        const forageOpts = {
            name: storeName,
            storeName: `${storeName}--values`
        };
        this.store = localforage.createInstance(forageOpts);
        if (opts.forageDriverDefinition) {
            this.store.defineDriver(opts.forageDriverDefinition)
                .catch((err) => {
                throw new Error(err);
            });
        }
        if (opts.forageDriver) {
            this.store.setDriver(opts.forageDriver)
                .catch((err) => {
                throw new Error(err);
            });
        }
        this.actionQueue = new ActionQueue({
            concurrency: opts.actionQueueConcurrency,
            executeAction: (action) => __awaiter(this, void 0, void 0, function* () {
                const result = yield this.getResource(action.resource)._triggerAction(action.action, action.parameters, { temporaryIdentifiers: action.temporaryIdentifiers });
                return result;
            }),
            applyMutation: (actionId, resource, action, parameters) => {
                this.getResource(resource).addPendingMutation(actionId, Date.now(), action, ...parameters);
            },
            updateMutation: (actionId, resource, parameters) => {
                this.getResource(resource).updatePendingMutation(actionId, ...parameters);
            },
            commitMutation: (actionId, resource, action, parameters) => {
                this.getResource(resource).commitPendingMutation(actionId, ...parameters);
            },
            cancelMutation: (actionId, resource) => {
                this.getResource(resource).cancelPendingMutation(actionId);
            },
            persistState: (state) => __awaiter(this, void 0, void 0, function* () {
                yield this.setItem('actionQueue', state);
            }),
            loadState: () => __awaiter(this, void 0, void 0, function* () {
                var _e;
                const state = (_e = (yield this.getItem('actionQueue'))) !== null && _e !== void 0 ? _e : null;
                return state;
            })
        });
        new Promise((resolve) => {
            let loaded = 0;
            Object.keys(this.resources).forEach((resourceName) => {
                const resource = this.resources[resourceName];
                resource.once('state:loaded', () => {
                    loaded++;
                    if (loaded === Object.keys(this.resources).length) {
                        resolve();
                    }
                });
                resource._setName(resourceName);
                resource._setPersist((state) => __awaiter(this, void 0, void 0, function* () {
                    yield this.setItem(`${resourceName}_state`, state);
                }));
                resource._setLoadState(() => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    const state = (_a = (yield this.getItem(`${resourceName}_state`))) !== null && _a !== void 0 ? _a : null;
                    return state;
                }));
                resource._setQueueAction((action, parameters, opts = {}) => __awaiter(this, void 0, void 0, function* () {
                    return yield this.actionQueue.queueAction(Object.assign({ resource: resourceName, action: action, parameters }, opts));
                }));
                resource._setUpdateQueuedAction((actionId, temporaryIdentifiers) => __awaiter(this, void 0, void 0, function* () {
                    yield this.actionQueue.updateQueuedAction(actionId, temporaryIdentifiers);
                }));
            });
        })
            .then(() => {
            var _a;
            void this.actionQueue.pickup();
            if ((_a = !opts.offline) !== null && _a !== void 0 ? _a : true) {
                if (this.connectionIndicator.isConnected()) {
                    this.actionQueue.start();
                }
            }
        })
            .catch((err) => {
            throw err;
        });
        if (opts.offline)
            this.disconnected = true;
        this.connectionIndicator.on('connection', (connected) => {
            if (this.disconnected)
                return;
            if (connected) {
                this.actionQueue.start();
                this.emit('connection', true);
            }
            else {
                this.actionQueue.pause();
                this.emit('connection', false);
            }
        });
    }
    isConnected() {
        return !this.disconnected && this.connectionIndicator.isConnected();
    }
    disconnect() {
        this.disconnected = true;
        this.actionQueue.pause();
        this.emit('connection', false);
    }
    reconnect() {
        this.disconnected = false;
        if (this.connectionIndicator.isConnected()) {
            this.emit('connection', true);
            this.actionQueue.start();
        }
    }
    getActionQueue() {
        return this.actionQueue;
    }
    getStore() {
        return this.store;
    }
    getResource(name) {
        const resource = this.resources[name];
        if (!resource)
            throw new Error(`Resource\`${name}\` not found`);
        return resource;
    }
    getConnectionIndicator() {
        return this.connectionIndicator;
    }
    preparePlugins(action, step) {
        return this.plugins.map((plugin) => {
            var _a, _b;
            // @ts-expect-error
            if (!((_a = plugin[action]) === null || _a === void 0 ? void 0 : _a[step])) {
                return null;
            }
            // @ts-expect-error
            return (_b = plugin[action]) === null || _b === void 0 ? void 0 : _b[step];
        })
            .filter(Boolean);
    }
    setItem(key, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const prePlugins = this.preparePlugins('setItem', 'before');
            const processPlugins = (key, value, plugins) => __awaiter(this, void 0, void 0, function* () {
                let index = 0;
                let processNext = true;
                let actualKey = key;
                let actualValue = value;
                while (processNext && index < plugins.length) {
                    processNext = false;
                    yield plugins[index](actualKey, actualValue, (k, v) => {
                        actualKey = k;
                        actualValue = v;
                        processNext = true;
                    });
                    index++;
                }
                return { key: actualKey, value: actualValue, processFurther: processNext };
            });
            const processedValues = yield processPlugins(key, value, prePlugins);
            if (!processedValues.processFurther) {
                return;
            }
            yield this.store.setItem(processedValues.key, processedValues.value);
            const postPlugins = this.preparePlugins('setItem', 'after');
            yield processPlugins(processedValues.key, processedValues.value, postPlugins);
        });
    }
    _getSetItem() {
        return this.setItem.bind(this);
    }
    getItem(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const prePlugins = this.preparePlugins('getItem', 'before');
            const processPlugins = (key, value, isBefore, plugins) => __awaiter(this, void 0, void 0, function* () {
                let index = 0;
                let processNext = true;
                let actualKey = key;
                let actualValue = value;
                while (processNext && index < plugins.length) {
                    processNext = false;
                    const fnResult = yield plugins[index](actualKey, actualValue, (k, v) => {
                        actualKey = k;
                        actualValue = v;
                        processNext = true;
                    });
                    if (!processNext && fnResult !== undefined) {
                        actualValue = fnResult;
                    }
                    index++;
                }
                return { key: actualKey, value: actualValue, processFurther: processNext };
            });
            const processedValues = yield processPlugins(key, null, true, prePlugins);
            if (!processedValues.processFurther) {
                return processedValues.value;
            }
            const result = yield this.store.getItem(processedValues.key);
            const postPlugins = this.preparePlugins('getItem', 'after');
            const processedValuesPost = yield processPlugins(processedValues.key, result, false, postPlugins);
            return processedValuesPost.value;
        });
    }
    _getGetItem() {
        return this.getItem.bind(this);
    }
}
