var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import EventEmitter from 'eventemitter3';
import { reactive, ref, toRaw, unref, watch } from 'vue';
import hash from 'object-hash';
import { debounce } from 'debounce';
import cloneDeep from 'lodash.clonedeep';
import { createTemporaryIdentifier } from '.';
// todo: symbols for all the property descriptors
export const mutationKey = Symbol('mutateFn');
const mutationContextKey = Symbol('mutation');
export const maxTriesKey = Symbol('maxTries');
export const consolidateKey = Symbol('consolidateFn');
export var MutationState;
(function (MutationState) {
    MutationState[MutationState["ready"] = 0] = "ready";
    MutationState[MutationState["cancelled"] = 1] = "cancelled";
    MutationState[MutationState["applied"] = 2] = "applied";
    MutationState[MutationState["committed"] = 3] = "committed";
})(MutationState || (MutationState = {}));
export class Mutation {
    constructor(fn, parameters) {
        this.temporaryIdentifiers = [];
        this.fn = fn;
        this.parameters = parameters;
        this.state = MutationState.ready;
    }
    setParameters(...parameters) {
        this.parameters = parameters;
    }
    getParameters() {
        return this.parameters;
    }
    addTemporaryIdentifier(label, temporaryId) {
        this.temporaryIdentifiers.push({ label, temporaryId, id: null });
    }
    getTemporaryIdentifiers() {
        return this.temporaryIdentifiers;
    }
    updateTemporaryIdentifier(label, id) {
        const temporaryIdentifier = this.temporaryIdentifiers.find(identifier => identifier.label === label);
        if (temporaryIdentifier) {
            temporaryIdentifier.id = id;
        }
    }
    cancel() {
        this.state = MutationState.cancelled;
    }
    getState() {
        return this.state;
    }
}
export function useContext(o) {
    // @ts-expect-error
    if (!o[mutationContextKey])
        return { mutation: null };
    // @ts-expect-error
    return { mutation: o[mutationContextKey] };
}
export class Resource extends EventEmitter {
    constructor() {
        super();
        this.name = '';
        this.pendingMutations = {};
        this.externalMutations = [];
        this.mutationsEmittingExternalMutations = {};
        this._queueAction = null;
        this.updateQueuedAction = null;
        this.persist = null;
        this.loadState = null;
        // expose state as a reactive object AND emit events, this way the consumer can decide
        this.state = reactive(this.initialState());
        this.mutatedState = ref(null);
        this.computeMutatedState();
        watch(this.state, () => {
            this.computeMutatedState();
            this.emit('state:update', this.getState(false));
            if (this.persist) {
                void this.persist(this.getState(false));
            }
        }, { deep: true });
        watch(this.mutatedState, () => {
            this.emit('mutatedState:update', this.getState(true));
        });
    }
    _setName(name) {
        this.name = name;
    }
    _setQueueAction(fn) {
        this._queueAction = fn;
    }
    _setUpdateQueuedAction(fn) {
        this.updateQueuedAction = fn;
    }
    _setLoadState(fn) {
        this.loadState = fn;
        void this.loadState()
            .then((state) => {
            if (state === null) {
                this.emit('state:loaded', this.getState());
                return;
            }
            Object.keys(state).forEach((key) => {
                // @ts-expect-error
                this.state[key] = state[key];
            });
            this.computeMutatedState();
            this.emit('state:loaded', this.getState());
        });
    }
    _setPersist(fn) {
        this.persist = debounce((state) => __awaiter(this, void 0, void 0, function* () {
            yield fn(state)
                .catch((err) => {
                throw new Error(`Failed to persist \`${this.name}\` state: \`${err.message}\``);
            });
        }), 100);
    }
    computeMutatedState() {
        this.mutationsEmittingExternalMutations = {};
        const newState = Object.assign({}, cloneDeep(toRaw(this.state)));
        const externalStateMutations = [];
        const externalStateMutationUpdates = [];
        const applyMutation = (type, id) => {
            if (type === 'internal') {
                const mutation = this.pendingMutations[id];
                const action = this[mutation.action];
                const propertyDescriptor = Object.getOwnPropertyDescriptor(action, mutationKey);
                if (!propertyDescriptor)
                    return;
                const { fn } = propertyDescriptor.value;
                let emittedExternalMutation = false;
                const temporaryIdentifiers = [];
                fn({
                    state: newState,
                    mutateResourceState: (module, fn) => {
                        if (this.mutationsEmittingExternalMutations[id] === hash(mutation.parameters))
                            return;
                        if (this.mutationsEmittingExternalMutations[id]) {
                            externalStateMutationUpdates.push({ id, ts: mutation.ts, module, fn });
                        }
                        else {
                            externalStateMutations.push({ id, ts: mutation.ts, module, fn });
                        }
                        emittedExternalMutation = true;
                    },
                    createTemporaryIdentifier: (label) => {
                        const temporaryId = createTemporaryIdentifier();
                        temporaryIdentifiers.push({ label, temporaryId });
                        return temporaryId;
                    },
                    isCommit: false
                }, ...mutation.parameters);
                if (emittedExternalMutation) {
                    this.mutationsEmittingExternalMutations[id] = hash(mutation.parameters);
                }
                if (temporaryIdentifiers.length > 0 && this.updateQueuedAction) {
                    this.updateQueuedAction(id, temporaryIdentifiers);
                }
            }
            else {
                const mutation = this.externalMutations[parseInt(id)];
                mutation.handler(newState);
            }
        };
        const mutations = Object.keys(this.pendingMutations)
            .map(id => ({ type: 'internal', id, ts: this.pendingMutations[id].ts }))
            .concat(this.externalMutations
            .map(({ ts }, index) => ({ type: 'external', id: `${index}`, ts })))
            .sort((a, b) => a.ts - b.ts);
        mutations.forEach(({ type, id }) => applyMutation(type, id));
        if (externalStateMutations.length) {
            this.emit('internal:externalMutations:create', externalStateMutations);
        }
        if (externalStateMutationUpdates.length) {
            this.emit('internal:externalMutations:update', externalStateMutationUpdates);
        }
        this.mutatedState.value = newState;
    }
    addPendingMutations(mutations) {
        mutations.forEach(({ id, ts, action, parameters }) => {
            this.pendingMutations[id] = { ts, action, parameters };
        });
        this.computeMutatedState();
    }
    addPendingMutation(id, ts, action, ...parameters) {
        this.pendingMutations[id] = { ts, action, parameters };
        this.computeMutatedState();
    }
    cancelPendingMutation(id) {
        const mutation = this.pendingMutations[id];
        if (mutation && Object.keys(this.mutationsEmittingExternalMutations).includes(id)) {
            this.emit('internal:externalMutations:cancel', [{ id }]);
        }
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.pendingMutations[id];
        this.computeMutatedState();
    }
    updatePendingMutation(id, ...parameters) {
        this.pendingMutations[id].parameters = parameters;
        this.computeMutatedState();
    }
    commitPendingMutation(id, ...parameters) {
        const externalStateMutations = [];
        const mutation = this.pendingMutations[id];
        if (!mutation)
            throw new Error(`No pending mutation with id ${id}`);
        const action = this[mutation.action];
        const propertyDescriptor = Object.getOwnPropertyDescriptor(action, mutationKey);
        if (!propertyDescriptor)
            return;
        const { fn } = propertyDescriptor.value;
        fn({
            state: this.state,
            mutateResourceState: (module, fn) => {
                externalStateMutations.push({ id, ts: mutation.ts, module, fn });
            },
            isCommit: true
        }, ...parameters);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.pendingMutations[id];
        this.emit('internal:externalMutations:commit', externalStateMutations);
        this.computeMutatedState();
    }
    addExternalMutations(mutations) {
        mutations.forEach(({ id, ts, fn }) => {
            this.externalMutations.push({ id, ts, handler: fn });
        });
        this.computeMutatedState();
    }
    updateExternalMutations(mutations) {
        mutations.forEach((mutation) => {
            this.externalMutations = this.externalMutations.filter(({ id }) => mutation.id !== id);
        });
        mutations.forEach(({ id, ts, fn }) => {
            this.externalMutations.push({ id, ts, handler: fn });
        });
        this.computeMutatedState();
    }
    commitExternalMutations(mutations) {
        mutations.forEach((mutation) => {
            this.externalMutations = this.externalMutations.filter(({ id }) => mutation.id !== id);
        });
        mutations.forEach(({ fn }) => {
            fn(this.state);
        });
        this.computeMutatedState();
    }
    cancelExternalMutations(ids) {
        let deletedMutations = false;
        ids.forEach((id) => {
            const mutationsWithId = this.externalMutations.filter(({ id: mutationId }) => mutationId === id);
            if (!mutationsWithId.length)
                return;
            deletedMutations = true;
            mutationsWithId.forEach((mutation) => {
                this.externalMutations.splice(this.externalMutations.indexOf(mutation), 1);
            });
        });
        if (!deletedMutations)
            return;
        this.computeMutatedState();
    }
    _triggerAction(action, args, opts = {}) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            // @ts-expect-error
            const descriptor = Object.getOwnPropertyDescriptor(this[action], mutationKey);
            const mutation = new Mutation(descriptor === null || descriptor === void 0 ? void 0 : descriptor.value.fn, args);
            if ((_a = opts.temporaryIdentifiers) === null || _a === void 0 ? void 0 : _a.length) {
                opts.temporaryIdentifiers.forEach((identifier) => {
                    mutation.addTemporaryIdentifier(identifier.label, identifier.temporaryId);
                });
            }
            const o = descriptor ? { [mutationContextKey]: mutation } : {};
            Object.setPrototypeOf(o, this);
            // @ts-expect-error
            const result = yield ((_b = Object.getOwnPropertyDescriptor(this[action], 'originalFn')) === null || _b === void 0 ? void 0 : _b.value.call(o, ...args));
            return {
                result,
                mutation
            };
        });
    }
    queueAction(action, args, opts = {}) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._queueAction)
                throw new Error('_queueAction is not set');
            // todo: set action dependencies, pass pending actions
            // todo: consolidate actions
            // @ts-expect-error
            const maxTriesDescriptor = Object.getOwnPropertyDescriptor(this[action], maxTriesKey);
            const _opts = {
                session: (_a = opts.session) !== null && _a !== void 0 ? _a : false
            };
            if (maxTriesDescriptor === null || maxTriesDescriptor === void 0 ? void 0 : maxTriesDescriptor.value) {
                _opts.maxTries = maxTriesDescriptor.value.tries;
                _opts.retryWaitTime = maxTriesDescriptor.value.retryWaitTime;
            }
            return yield this._queueAction(action, args, opts);
        });
    }
    getReactiveState() {
        return this.mutatedState;
    }
    getState(mutated = true) {
        if (mutated)
            return cloneDeep(unref(this.mutatedState));
        return cloneDeep(toRaw(this.state));
    }
    reconcile() {
        return __awaiter(this, void 0, void 0, function* () {
            throw new Error('Not implemented');
        });
    }
}
