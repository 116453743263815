import { v4 as uuid } from 'uuid';
export function createTemporaryIdentifier() {
    return `erm_id_${uuid()}`;
}
export function isTemporaryIdentifier(id) {
    if (typeof id !== 'string') {
        return false;
    }
    return id.startsWith('erm_id_');
}
